<template>
  <div class="hexiaoorder">
    <van-nav-bar
      title="核销订单"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-cell class="row1" :border="false" >
      <template #title>
        <div class="title_main">
          <div class="logo">
            <van-image
              :src="activity.logo"
              width="60px"
              height="60px"
              lazy-load
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <div class="title">{{activity.title}}</div>
        </div>
      </template>
      <template #label>
        <div>{{activity.description}}</div>
      </template>
    </van-cell>
    <van-cell class="row1 pb10" :border="false" center>
      <template #title>
        <van-count-down :time="activity.stoptime" format="距结束：DD天HH时mm分ss秒" />
      </template>
    </van-cell>
    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          v-model="ordersn"
          name="ordersn"
          label="旗舰店订单号"
          placeholder="请填写旗舰店订单号"
          required
          :rules="[{ required: true, message: '请填写旗舰店订单号' }]"
        />
        <van-field
          v-model="agentcode"
          name="agentcode"
          label="推荐人邀请码"
          placeholder="请填写推荐人邀请码"
          :required="noAgentcode == '1' ? true : false"
          :rules="[{ required: noAgentcode == '1' ? true : false, message: '请填写推荐人邀请码' }]"
        />
        <van-cell class="tuijianren">
          <template #title>
            <div>是否有推荐人</div>
          </template>
          <template #default>
            <van-radio-group v-model="noAgentcode" direction="horizontal">
              <van-radio name="1" checked-color="#EE0A24" icon-size="14px">有推荐人</van-radio>
              <van-radio name="2" checked-color="#EE0A24" icon-size="14px">无推荐人</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-cell class="tuijianren">
          <template #title>
            <div>选择下单奖励</div>
          </template>
          <template #default>
            <van-radio-group
              v-if="activity.prizeList && activity.prizeList.length > 0"
              v-model="prizetype"
              direction="horizontal"
            >
              <van-radio v-for="(item, index) in activity.prizeList" :key="index" :name="item.type" checked-color="#EE0A24" icon-size="14px">{{item.title}}</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <div class="order_price_list">
          <van-radio-group
              v-model="orderprice"
              direction="horizontal"
            >
              <div style="width: 100%;" v-for="(item, index) in activity.reward" :key="index">
                <van-cell
                  v-if="item.prizetype == prizetype"
                  :value="'订单满' + item.price + '元'"
                  clickable
                  @click="rewwardClick(item.price,index)"
                >
                  <template #icon>
                    <van-radio :name="item.price" checked-color="#EE0A24" icon-size="14px" />
                  </template>
                </van-cell>
                <div class="prizeinfo" v-show="orderprice == item.price ? true : false">
                  <van-cell v-if="prizetype == 1" :value="'奖励' + item.prize + '元'" />
                  <div class="zengpin" v-if="prizetype == 2">
                    <van-card
                      class="goods_list"
                      v-for="(val, key) in item.goodslist"
                      :key="key"
                      :desc="val.skutitle ? '已选SKU：'+val.skutitle : ''"
                      :title="val.title"
                      :thumb="val.thumb"
                      lazy-load
                    >
                      <template #price>
                        <van-tag class="check_sku_show" @click.stop="showSku(val.id, key)">{{val.skuid && val.skuid > 0 ? '重新选择SKU' : '选择SKU'}}</van-tag>
                      </template>
                    </van-card>
                    <van-cell center is-link class="checkaddress" @click.stop="checkaddress" v-if="!(address && address.id && address.id > 0)" value="选择收货地址">
                      <template #icon>
                        <van-icon slot="icon" name="add-square" size="30px" color="#EE0A24" />
                      </template>
                    </van-cell>
                    <van-cell center is-link class="checkaddress" @click.stop="checkaddress" v-if="address && address.id && address.id > 0">
                      <template #default>
                        <div>{{address.name ? address.name : ''}} {{address.mobile ? address.mobile : ''}}</div>
                        <div>{{address.province ? address.province : ''}} {{address.city ? address.city : ''}} {{address.area ? address.area : ''}} {{address.street ? address.street : ''}}</div>
                      </template>
                    </van-cell>
                    <div></div>
                  </div>
                </div>
              </div>
            </van-radio-group>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <!-- 产品sku -->
    <van-sku
      v-model="showBase"
      :sku="skuData.sku"
      :goods="skuData.goods"
      :goods-id="skuData.goodsid"
      :hide-stock="skuData.sku.hidestock"
      reset-selected-sku-on-hide
      :close-on-click-overlay="closeOnClickOverlay"
      :custom-sku-validator="customSkuValidator"
      @buy-clicked="onBuyClicked"
      :show-add-cart-btn="false"
      lazy-load
      ref="vanskudata"
      buy-text="确认选择"
    >
      <template #sku-stepper>
        <div>
          
        </div>
      </template>
    </van-sku>
  </div>
</template>
<script>
import skuData from "@/components/data.js";
export default {
  name: "hexiaoOrder",
  data() {
    return {
      id: 0,
      shareid: 0,
      merchid: 0,
      activity: {},
      noAgentcode: '1',
      prizetype: '',
      orderprice: '',
      ordersn: '',
      agentcode: '',
      address: {},
      goods: [],
      activityIndex: -1,
      goodsIndex: -1,
      showBase: false,
      skuData: skuData,
      skuTitle: "", // sku标题
      closeOnClickOverlay: true,
      goodsid: 0,
    }
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "核销单号");
    this.init();
  },
  activated() {
    if(localStorage.getItem("getAddress") && localStorage.getItem("getAddress") == '1') {
      localStorage.removeItem("getAddress");
      this.address = localStorage.getItem("address") ? JSON.parse(localStorage.getItem("address")) : {};
      console.log(this.address);
    }
  },
  methods: {
    init() {
      localStorage.removeItem('address');
      console.log("init()");
      this.getdetail();
    },
    onClickLeft() {
      this.$router.back();
    },
    getdetail() {
      console.log("getdetail");
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/activity/getdetail",
          that.$qs.stringify({
            id: that.id
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.activity = res.data.data;
          } else {
            that.$toast(res.data.msg ? res.data.msg : "获取活动详情失败")
          }
        })
        .catch(err => {
          that.$toast.clear();
          console.log(err);
        })
    },
    // 选择订单金额
    rewwardClick(price, index) {
      this.orderprice = price;
      this.activityIndex = index;
    },
    showSku(id, index) {
      console.log(id, index);
      if(id == this.goodsid) {
        this.goodsid = id;
        this.goodsIndex = index;
        this.showBase = true;
      }else {
        this.goodsid = id;
        this.goodsIndex = index;
        this.getGoodsInfo();
      }
    },
    getGoodsInfo () {
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/goods/get_info",
          that.$qs.stringify({
            id: that.goodsid,
            merchid: that.merchid,
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          const spec = [];
          const option = [];
          if(res.data.info && res.data.info.id && parseInt(res.data.info.id) > 0) {
            
            that.showBase = true;
            that.goods = res.data.info;
            that.skuData.goods.title = that.goods.title;
            that.skuData.goodsid = that.goodsid;
            that.skuData.goods.picture = that.goods.thumb;
            // 规格信息
            if (res.data.spec) {
              that.goods.spec = res.data.spec;
              
              res.data.spec.forEach((item, index) => {
                const arr = {
                  k: item.title ? item.title : '',
                  "k_id": item.id,
                  v: item.children,
                  "k_s": "s" + (index + 1),
                  largeImageMode: false,
                };
                spec.push(arr);
              });
              that.skuData.sku.tree = spec;
            }
            if (res.data.option) {
              that.goods.option = res.data.option;
              
              res.data.option.forEach((item) => {
                let specid = [];
                if (item.specs) {
                  specid = item.specs.split("_");
                }
                const arr = {
                  id: item.id,
                  price: '', //价格
                  s1: specid[0] ? specid[0] : 0,
                  s2: specid[1] ? specid[1] : 0,
                  s3: specid[2] ? specid[2] : 0,
                  "stock_num": parseInt(item.stock), //库存
                };
                option.push(arr);
              });
              that.skuData.sku.list = option;
            }
          }else {
            that.skuData.sku.tree = spec;
            that.skuData.sku.list = option;
            that.$toast(res.data.msg ? res.data.msg : "产品已下架")
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    /* 未选择规格提示 */
    customSkuValidator() {
      return "请选择" + this.skuTitle;
    },
    // 确认选择按钮
    onBuyClicked(data) {
      console.log(data,this.goodsIndex,this.activityIndex);
      let skuid = 0;
      skuid = data.selectedSkuComb.id;
      let skutitle = '';
      console.log(skuid,this.goods.option);
      this.goods.option.forEach(item => {
        if (item.id == skuid) {
          skutitle = item.title
        }
      })

      this.activity.reward[this.activityIndex].goodslist[this.goodsIndex].skuid = skuid;
      this.activity.reward[this.activityIndex].goodslist[this.goodsIndex].skutitle = skutitle;
      this.showBase = false;
    },
    onSubmit() {
      console.log("onSubmit");
      this.$dialog.confirm({
        title: '确认提交？',
        message: '提交后将不可修改是否确认提交？',
      }).then(() => {
        // on close
        this.doSubmit();
      }).catch((err) => {
        console.log(err);
      });
    },
    // 提交数据
    doSubmit() {
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '提交中...',
        forbidClick: true,
        duration: 0
      })
      
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/activity/addordersn",
          that.$qs.stringify({
            id: that.id,
            ordersn: that.ordersn,
            agentcode: that.agentcode,
            noAgentcode: that.noAgentcode,
            goods: that.prizetype == 2 && that.goodsIndex >= 0 ? JSON.stringify(that.activity.reward[that.activityIndex].goodslist) : '{}',
            address: that.prizetype == 2 ? JSON.stringify(that.address) : '{}',
            prizetype: that.prizetype,
            orderprice: that.orderprice,
            prize: that.activityIndex >= 0 ? that.activity.reward[that.activityIndex].prize : '',
            commission: that.activityIndex >= 0 ? that.activity.reward[that.activityIndex].commission : '',
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if(res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : '保存成功');
          }else {
            that.$toast(res.data.msg ? res.data.msg : '保存失败');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 选择收货地址
    checkaddress() {
      localStorage.setItem("getAddress", "1")
      this.$router.push({
        name: "AddressList",
        query: {
          type: 'activity',
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
  },
}
</script>
<style lang="less">
.hexiaoorder {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f8f8f8;
  .van-nav-bar .van-icon {
    color: #333;
  }

  .row1 {
    overflow: hidden;
    padding-bottom: 0;
    .van-cell__title {
      flex: 1;
      overflow: hidden;
      text-align: left;
      .title_main {
        display: flex;
        overflow: hidden;
        .logo {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 10px;
          .van-image {
            display: block;
          }
        }
        .title {
          flex: 1;
          overflow: hidden;
          font-size: 16px;
        }
      }
      .van-cell__label {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .from {
    margin-top: 10px;
    overflow: hidden;
    background-color: #fff;
    padding-bottom: 50px;
    .van-cell__value {
      flex: 1;
    }
    .tuijianren {
      .van-cell__title {
        margin-right: 12px;
      }
      .van-radio {
        flex: 1;
      }
    }
    .order_price_list {
      .goods_list {
        background-color: #fff;
        .van-card__title,
        .van-card__bottom {
          text-align: left;
        }
        .van-card__price {
          padding: 10px 5px;
        }
      }
      .checkaddress {
        .van-cell__value {
          margin: 0 10px;
        }
      }
    }
    
  }
  .van-sku-row,
  .van-sku-header-item,
  .van-card__desc {
    text-align: left;
  }
  .van-sku__goods-price {
    display: none;
  }
  .pb10 {
    padding-bottom: 10px;
  }
}
</style>